<template>
  <div class="index">
    <nav-bar/>
    <index-banner/>
    <hot-goods/>
    <!-- <invite/> -->
    <daifa-guide/>
    <bottom-footer/>
    <back-top/>
  </div>
</template>

<script>
import NavBar from 'components/navbar/NavBar'
import IndexBanner from 'views/index/childComps/IndexBanner'
import HotGoods from 'views/index/childComps/HotGoods'
// import Invite from 'views/index/childComps/Invite'
import DaifaGuide from 'views/index/childComps/DaifaGuide'
import BottomFooter from 'components/bottomfooter/BottomFooter'
import BackTop from 'components/backtop/BackTop'

export default {
  components: {
    NavBar,
    IndexBanner,
    HotGoods,
    // Invite,
    DaifaGuide,
    BottomFooter,
    BackTop
  }
}
</script>

<style scoped src="styles/views/index/index.css">
</style>
