import { render, staticRenderFns } from "./IndexBanner.vue?vue&type=template&id=0c03dd60&scoped=true&"
import script from "./IndexBanner.vue?vue&type=script&lang=js&"
export * from "./IndexBanner.vue?vue&type=script&lang=js&"
import style0 from "styles/views/index/indexbanner.css?vue&type=style&index=0&id=0c03dd60&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c03dd60",
  null
  
)

export default component.exports