<template>
  <div class="usermenu">
    <div class="usermenu-top">
      <!-- 未登录 -->
      <div class="islogin">
        <el-avatar
          class="avatar-user"
          v-if="userInfo"
          :size="72"
          :src="userInfo.avatar"
          @error="errorHandler"
        >
          <img
            src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"
          />
        </el-avatar>
        <el-avatar
          class="avatar-user"
          v-else
          :size="72"
          :src="userUrl"
          @error="errorHandler"
        >
          <img
            src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"
          />
        </el-avatar>
        <div class="title">
          <span v-if="userInfo">Hi,{{userInfo.username}}</span>
          <span v-else>Hi,欢迎来到{{$store.getters.getSiteInfo.name}}~</span>
        </div>
        <a v-if="userInfo" href="javascript:void(0);" class="dl">
          <span @click="toMall">进入远大商城</span>
        </a>
        <a v-if="!userInfo" href="javascript:void(0);" class="dl">
          <span @click="toLogin">登录</span>
        </a>
        <a v-if="!userInfo" href="javascript:void(0);" class="mfzc">
          <span @click="toRegister">免费注册</span>
        </a>
      </div>
      <!-- 登录 -->
      <div class="nologin"></div>
    </div>
    <div class="usermenu-bottom">
      <div class="usermenu-bottom-item" @click="onlineService">
        <div class="icon-warp">
          <i class="el-icon-service"></i>
        </div>
        <span>在线客服</span>
      </div>
      <!-- <div class="usermenu-bottom-item" @click="helpCenter">
        <div class="icon-warp">
          <i class="el-icon-view"></i>
        </div>
        <span>帮助中心</span>
      </div> -->
      <!-- <div class="usermenu-bottom-item" @click="toFriends">
        <div class="icon-warp">
          <i class="el-icon-user"></i>
        </div>
        <span>邀请好友</span>
      </div> -->
      <div class="usermenu-bottom-item" @click="toDaifa">
        <div class="icon-warp">
          <i class="el-icon-document"></i>
        </div>
        <span>代发指南</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      userUrl: require('assets/icons/user.png')
    }
  },
  computed: {
    // 获取用户信息
    userInfo () {
      return this.$store.getters.getUserInfo
    }
  },
  methods: {
    errorHandler () {
      return true
    },
    // 进入商品商城
    toMall () {
      this.$router.push('/orderAdmin/purchase')
    },
    // 去登录
    toLogin () {
      this.$router.push('login')
    },
    // 去注册
    toRegister () {
      this.$router.push('register')
    },
    // 在线客服
    onlineService () {
      window.open(this.$store.getters.getSiteInfo.online_service, '_blank')
    },
    // 帮助中心
    helpCenter () {
      window.open(this.$store.getters.getSiteInfo.online_service, '_blank')
    },
    // 邀请好友
    toFriends () {
      this.$router.push('/account/InviteFriends')
    },
    // 代发指南
    toDaifa () {
      document.documentElement.scrollTop = 2020
    }
  }
}
</script>

<style scoped src="styles/views/index/indexbanneritem.css">
</style>
